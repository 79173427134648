<script>
import {defineComponent} from 'vue';
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import {notification} from "@/helpers/notifications";
import {ACTIVITY_LOGS} from "@/graphql/queries/activityLogs/activityLogs";
import {format} from "date-fns";
import {createdAtFormat, getProfileName} from "@/helpers/helper";

export default defineComponent({
  name: "ContainerLogs",
  components: {
    flatPickr
  },
  data: () => ({
    form: {
      date: null
    },
    defaultDateConfig: {
      dateFormat: "Y-m-d",
      defaultDate: null,
    },
    activities: [],
  }),
  mounted() {
    this.logs();
  },
  methods: {
    getProfileName,
    createdAtFormat,

    async logs() {
      await this.activityLogs({
        query: ACTIVITY_LOGS,
        fetchPolicy: "network-only",
      });
    },

    async logsByDate() {

      console.log({
        from: this.form.date,
        to: this.form.date
      });


      await this.activityLogs({
        query: ACTIVITY_LOGS,
        fetchPolicy: "network-only",
        variables: {
          input: {
            from: this.form.date,
            to: this.form.date
          }
        }
      });
    },

    async activityLogs(query) {
      try {
        const {data} = await this.$apollo.query(query);

        this.activities = (data.activityLogs || [])
            .reduce((acc, value) => {
              const createdAt = format(new Date(value.createdAt), 'yyyy-MM-dd');

              if (!acc[createdAt]) {
                acc[createdAt] = [];
              }

              acc[createdAt].push(value);

              return acc;

            }, {});

        return this.activities;

      } catch (errors) {
        notification('error', errors.message);
        return [];
      }
    }
  },
  watch: {
    'form.date': function (value) {
      console.log('value', value);

      if (value) {
        console.log('logsByDate');
        this.logsByDate();
      } else {
        console.log('logs');
        this.logs();
      }
    }
  }
});
</script>

<template>
  <b-row>
    <b-col lg="12">
      <div>
        <h6>Filtrar por Fecha</h6>

        <b-row>
          <b-col
              lg="6"
          >
            <div class="mb-3 d-flex">
              <!--              <label class="form-label mb-0">Fecha</label>-->
              <flat-pickr
                  v-model="form.date"
                  :config="defaultDateConfig"
                  class="form-control"
              >
              </flat-pickr>
            </div>
          </b-col>
        </b-row>

        <div class="timeline">

          <div
              class="timeline-item left"
              v-for="(logs, key, index) in activities"
              :key="index"
              :class="index%2 === 0 ? 'left' : 'right' "
          >
            <i class="icon ri-user-3-fill"></i>

            <div class="date fs-13">{{ createdAtFormat(key) }}</div>

            <div
                class="content mb-2"
                v-for="(log, key) in logs"
                :key="key"
            >
              <div class="d-flex">

                <div class="flex-shrink-0">
                  <img src="@/assets/images/placeholders/placeholder_users.png" alt="" class="avatar-sm rounded">
                </div>

                <div class="flex-grow-1 ms-3">
                  <h5 class="fs-14">@{{ log.systemUser?.name }} {{ log.systemUser?.lastName }}
                    <small class="text-muted fs-13 fw-normal">- {{ createdAtFormat(log.createdAt) }}</small>
                  </h5>
                  <p class="text-muted mb-2">
                    {{ log.logType }}
                  </p>
                  <div class="hstack gap-2">
                    <b-link class="btn btn-sm btn-light">
                      {{ getProfileName(log.systemUser?.profile) }}
                    </b-link>
                  </div>
                </div>
              </div>
            </div>
          </div>

<!--          <div class="timeline-item right">-->
<!--            <i class="icon ri-user-3-fill"></i>-->
<!--            <div class="date fs-13">Miércoles 16 Agosto 2023</div>-->
<!--            <div class="content">-->
<!--              <div class="d-flex">-->

<!--                <div class="flex-shrink-0">-->
<!--                  <img src="@/assets/images/placeholders/placeholder_users.png" alt="" class="avatar-sm rounded">-->
<!--                </div>-->

<!--                <div class="flex-grow-1 ms-3">-->
<!--                  <h5 class="fs-14">@UrrutiaGeo-->
<!--                    <small class="text-muted fs-13 fw-normal">- Miércoles 16 de Agosto 2023 16:19:11</small>-->
<!--                  </h5>-->
<!--                  <p class="text-muted mb-2">-->
<!--                    Inició Sesión en la Plataforma Web-->
<!--                  </p>-->
<!--                  <div class="hstack gap-2">-->
<!--                    <b-link class="btn btn-sm btn-light">-->
<!--                      Administrador de Plataforma-->
<!--                    </b-link>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="timeline-item right">-->
<!--            <i class="icon ri-stack-line"></i>-->
<!--            <div class="date fs-13">Miércoles 16 Agosto 2023</div>-->
<!--            <div class="content">-->
<!--              <div class="d-flex">-->

<!--                <div class="flex-shrink-0">-->
<!--                  <img src="@/assets/images/placeholders/placeholder_users.png" alt="" class="avatar-sm rounded">-->
<!--                </div>-->

<!--                <div class="flex-grow-1 ms-3">-->
<!--                  <h5 class="fs-14">@UrrutiaGeo-->
<!--                    <small class="text-muted fs-13 fw-normal">- Miércoles 16 de Agosto 2023 16:19:11</small>-->
<!--                  </h5>-->
<!--                  <p class="text-muted mb-2">-->
<!--                    Inició Sesión en la Plataforma Web-->
<!--                  </p>-->
<!--                  <div class="hstack gap-2">-->
<!--                    <b-link class="btn btn-sm btn-light">-->
<!--                      Administrador de Plataforma-->
<!--                    </b-link>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

        </div>
      </div>
    </b-col>
  </b-row>
</template>
