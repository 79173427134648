<script>
import {defineComponent} from 'vue';
import appConfig from "../../../app.config.json";
import PageHeader from "@/components/page-header.vue";
import Layout from "@/layouts/main.vue";
import ContainerActivityLogs from "@/views/activity-logs/container-activity-logs.vue";

export default defineComponent({
  name: "ContainerActivityLog",
  page: {
    title: 'Activity Logs',
    meta: [{name: "description", content: appConfig.description}]
  },
  components: {
    PageHeader,
    Layout,
    ContainerActivityLogs
  },
  data: () => ({
    title: '',
    items: [
      {
        text: "Activity Logs",
        href: "/",
      },
      {
        text: "Logs",
        active: true,
      },
    ]
  }),
});
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>

    <ContainerActivityLogs/>

  </Layout>
</template>
