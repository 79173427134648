import gql from 'graphql-tag'

export const ACTIVITY_LOGS = gql`
    query($input: FilterActivityLogDateRange) {
        activityLogs(createdAt: $input) {
            id
            log
            logTable
            logType
            action
            systemUser {
                id
                name
                lastName
                profile
            }
            data
            createdAt
        }
    }
`
